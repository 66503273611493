<template>
  <page-view title="故障报修记录详情" left-arrow>
    <van-cell-group>
      <van-cell title="工单号" :label="form.gzdh">{{zfdmFormatter('SQZT',form.gzdzt)}}</van-cell>
    </van-cell-group>

    <van-cell-group title="用户信息">
      <van-cell title="用户编号">{{form.yhbh}}</van-cell>
      <van-cell title="用户名称" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.yhmc}}</span>
      </van-cell>
      <van-cell title="联系方式">{{form.yhdh}}</van-cell>
      <van-cell title="用户地址" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.yhdz}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="报修信息">
      <van-cell title="诉求时间">{{form.sqsj}}</van-cell>
      <van-cell title="诉求类型">{{zfdmFormatter('SQLX',form.sqlx)}}</van-cell>
      <van-cell title="诉求内容" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.bxnr}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="处理信息">
      <van-cell title="处理时间">{{form.cljssj}}</van-cell>
      <van-cell title="处理方案" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.bxclfa}}</span>
      </van-cell>
      <van-cell title="处理结果" title-style="flex-basis: 5em;flex-grow: 0;">
        <span class="text-left" style="display: inline-block;">{{form.cljg}}</span>
      </van-cell>
    </van-cell-group>

    <van-cell-group title="处理进度">
      <div class="timeline">
        <div :class="['timeline-Item', item.hjzt=='1' ? 'is-success': '']" v-for="(item,idx) in bxclProgress"
          :key="item.hjid">
          <div class="timeline-Line"></div>
          <div class="timeline-Header">
            <span class="timeline-icon">{{idx+1}}</span>
            <h2 class="timeline-title">{{item.hjmc}}</h2>
          </div>
          <div class="timeline-Content">

          </div>
        </div>
      </div>
    </van-cell-group>
  </page-view>
</template>

<script>
import { mapGetters } from 'vuex';

import YhbhSelect from '@/components/YhbhSelect/index.vue';
import VantSelect from '@/components/VantSelect/index.vue';
import VantDatetimeSelect from '@/components/VantDatetimeSelect/index.vue';

import {
  zfdmManageService_getZfdmByDmzl
} from '@/api/zfdm'
import { gzdGzsl_add } from '@/api/psdmszhwh/zhwhschema/GzdGzslAPI';

export default {
  name: 'gzbx',
  components: {
    YhbhSelect,
    VantSelect,
    VantDatetimeSelect
  },
  data() {
    return {
      form: {
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',
        sqlx: '0',
        bxnr: ''
      },
      zfdmOptions: {
        'SQLX': [], // 诉求类型
        'SQZT': [], // 诉求状态
      }
    }
  },
  computed: {
    ...mapGetters(['jgbm','czyh']),
    bxclProgress() {
      let gzdzt = this.form.gzdzt;
      let list = [];
      for(let i = 0 ; i < this.zfdmOptions['SQZT'].length; i++) {
        const item = this.zfdmOptions['SQZT'][i];
        list.push({
          hjid: item.dmbh,
          hjmc: item.dmnr.split("-")[1],
          hjzt: gzdzt >= item.dmbh ? '1':'0'
        })
      }
      return list
    }
  },
  methods: {
    yhbhChangeHandle(yhxx) {
      this.form.yhbh = yhxx.yhbh;
      this.form.yhmc = yhxx.yhmc;
      this.form.yhdz = yhxx.yhdz;
      this.form.yhdh = yhxx.lxsj || yhxx.lxdh;
    },
    handleIsNilChange(val) {
      if (val == '1') {
        this.form.yhbh = '非系统用户';
      } else {
        this.form.yhbh = '';
        this.form.yhmc = '';
        this.form.yhdh = '';
        this.form.yhdz = '';
      }
    },
    resetForm() {
      this.form = {
        isNil: '0',
        yhbh: '',
        yhmc: '',
        yhdz: '',
        yhdh: '',

        sqsj: new Date(),
        yhsqlx: '0',
        bxnr: ''
      };
    },
    onSubmit(values) {
      this.$toast.loading('正在提交...'),
      console.log('submit', values);

      let params = values;
      params.jgbm = this.jgbm;
      params.bxslr = "微信公众号";
      params.bxslrid = this.czyh;
      params.gzdh = Date.now();
      params.gzdzt = '0';

      gzdGzsl_add(params).then(res => {
        this.$toast.clear();
        this.resetForm();
        console.log(res)
      }).catch(err => {
        this.$toast.clear();
        this.$toast("保修信息提交失败:"+err.message);
      });
    },
    onFailed({values, errors}) {
      console.log('failed:, Errors:', errors)
      this.$notify({ type: 'danger', message: errors[0].message});
    },
    fetchZFDM(dmzl) {
      zfdmManageService_getZfdmByDmzl(dmzl).then(res => {
        this.zfdmOptions[dmzl] = res.content;
      });
    },
    zfdmFormatter(dmzl, value) {
      let zfdmArr = this.zfdmOptions[dmzl].filter(item => item.dmbh == value);
      if (zfdmArr.length) {
        return zfdmArr[0].dmnr.split("-")[1];
      } 
      return '';
    },
  },
  mounted() {
    console.log(this.$route)
    if (this.$route.query && this.$route.query.gzdh) {
      this.form = this.$route.query;
    }
    for(let dmzl in this.zfdmOptions) {
      this.fetchZFDM(dmzl);
    }
  }
}
</script>


<style lang="scss" scoped>

.flex-2 {
  flex: 2;
}
.timeline /deep/ {
  margin: 10px;
  .timeline-Item {
    position: relative;
    padding: 10px 0 20px;
    box-sizing: border-box;
  }
  .timeline-Header {
    position: relative;
    height: 30px;
  }
  .timeline-title {
    font-size: 14px;
    padding-left: 30px;
  }
  .timeline-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 0;
    left: 0;
    color: #333;
    border-radius: 50%;
    text-align: center;
    background-color: #ededed;
    margin-right: 8px;
    z-index: 1;
    font-size: 14px;
    padding: 2px;
  } 
  .timeline-Item.is-success .timeline-icon {
    background-color: green;
    color: #fff;
  }
  .timeline-Item.is-success .timeline-Line {
    background-color: green;
  }
  .timeline-Item.is-success .timeline-title {
    color: green;
  } 

  .timeline-Line {
    position: absolute;
    top: 10px;
    left: 0.5em;
    width: 2px;
    bottom: -10px;
    background: #c0c4cc;
    z-index: 0;
  }

  .timeline-Item:last-child .timeline-Line {
    display: none;
  }
}
</style>